.circle-icon {
  background-color: #dd4b39;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 9px 4px 0px rgba(0, 0, 0, 0.3);
}

.clickable {
  cursor: pointer;
}
